import styled from 'styled-components';
import { motion } from 'framer-motion';

export const TestimonialsWrapper = styled(motion.section)`
    min-height: 656px;
    background: #fff;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 1;
    @media screen and (max-width: 1300px) {
      height: min-content;
    }
    @media screen and (max-width: 480px) {
      height: min-height;
      padding: 30px 0;
    }
`


export const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 620px;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  @media screen and (max-width: 1300px) {
    bottom: 120px;
    transform: unset;
  }
  @media screen and (max-width: 1110px) {
    text-align: center;
    width: calc(80% - 60px);
    position: relative;
    top: unset;
    left: unset;
    transform: unset;
    right: unset;
    bottom: unset;
  }
  @media screen and (max-width: 480px) {
    width: calc(100% - 60px);
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media screen and (max-width: 1110px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  @media screen and (max-width: 1110px) {
    flex-direction: column;
  }
`

export const Avatar = styled(motion.img)`
  width: 120px;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  background-color: #BEFFAD; 
  margin: 0 20px 20px 0;
  @media screen and (max-width: 1110px) {
    flex-direction: column;
    margin-right: 0;
  }
`;


export const Column = styled.div`
  display: flex;
  flex-direction: column;
`
export const Name = styled(motion.p)`
  color: var(--title-color);
  font-size: 36px;
  font-weight: 700;
  white-space: pre-wrap;
  word-break: break-word;
  word-wrap: break-word;
  @media screen and (max-width: 480px) {
    font-size: 30px;
  }
}
`

export const Title = styled(motion.p)`
  font-size: 30px;
  margin-bottom: 10px;
  color: var(--title-color);
  white-space: pre-wrap;
  word-break: break-word;
  word-wrap: break-word;
  @media screen and (max-width: 480px) {
    font-size: 24px;
  }
`;

export const Info = styled(motion.p)`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--content-color);
  font-size: 30px;
  white-space: pre-wrap;
  word-break: break-word;
  word-wrap: break-word;
  @media screen and (max-width: 480px) {
    font-size: 24px;
  }
`;


export const ScrollWrap = styled(motion.div) `
  position: absolute;
  width: 100%;
  white-space: nowrap;
  left: 0;
  bottom: -15px;
  color: var(--title-color);
  height: 100px;
  line-height: 100px;
  overflow: hidden;
  // @media screen and (max-width: 1920px) {
  //   font-size: 70px;
  //   height: 90px;
  //   line-height: 90px;
  // }
  // @media screen and (max-width: 1300px) {
  //   height: 80px;
  //   line-height: 80px;
  // }
  // @media screen and (max-width: 480px) {
  //   font-size: 38px;
  // }
  .parallax {
  overflow: hidden;
  letter-spacing: -2px;
  line-height: 1;
  margin: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
}

.parallax .scroller {
  font-weight: 600;
  // text-transform: uppercase;
  font-size: 64px;
  display: flex;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
}

.parallax span {
  display: block;
  margin-right: 30px;
}
`

export const ScrollTextContent = styled(motion.li)`
  list-style: none;
  position: absolute;
  left: 0%;
  font-size: 80px;
  letter-spacing: 2px;
  white-space: nowrap;
  text-align: center;
  &:nth-child(2) {
    left: -100%;
  }
  &:nth-child(3) {
    left: -100%;
  }
` 
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as proofRight } from "../../images/proof.right.svg"


export const NewsSection = styled.div`
  padding: 130px 0 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fff;
  position: relative;
  overflow: hidden;
  @media screen and (max-width: 960px) {
    padding: 200px 0 60px;
  }
  @media screen and (max-width: 480px) {
    padding: 120px 0 60px;
  }
`;

export const NewsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 80%;
  @media screen and (max-width: 1300px) {
    margin: 0 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 60px);
  }
  // @media (min-width: 1500px) {
    
  // }
`;

export const NewsHeading = styled.h1`
  color: #1E3666;
  font-size: 48px;
  margin-bottom: 200px;
  @media screen and (max-width: 960px) {
    margin-bottom: 160px;
  }
  @media screen and (max-width: 480px) {
    margin-bottom: 60px;
    font-size: 40px;
  }
`;

export const NewsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const NewsCard = styled(Link)`
  background: #fff;
  box-shadow: 0 2px 15px #0000001a;
  width: 100%;
  text-decoration: none;
  border-radius: 4px;
  margin-right: 24px;
  &:last-child {
    margin-right: 0
  }
  &:hover {
    transform: scale(1.05);
    transition: all 0.3s ease-out;
    color: #1c2237;
  }
  @media screen and (max-width: 960px) {
    width: 90%;
    margin-right: 0;
    margin-bottom: 30px;
    &:hover {
      transform: none;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  @media (min-width: 1500px) {
    width: 100%;
  }
`;

export const NewsCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  align-items: left;
  color: #25313C;
`;

export const NewsCardIcon = styled.img`
  width: 100%;
  aspect-ratio: 3 / 2;
  background-color: #D9D9D9; 
  margin-bottom: 20px;
`;

export const NewsCardPlan = styled.h3`
  margin-bottom: 5px;
  font-size: 24px;
  min-height: 48px;
  font-weight: 500;
  color: rgb(37, 49, 60);
`;

export const NewsCardCost = styled.h4`
  font-size: 40px;
`;

export const NewsCardLength = styled.p`
  font-size: 14px;
  margin-bottom: 24px;
`;

export const NewsCardFeatures = styled.ul`
  margin: 16px 0 32px;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #a9b3c1;
`;

export const NewsCardFeature = styled.li`
  margin-bottom: 10px;
`;


export const ProofRightIcon = styled(proofRight)`
    width: 364px;
    height: 417px;
    top: -60px;
    right: -58px;
    position: absolute;
    @media screen and (max-width: 1200px) {
        width: 312px;
    }
    @media screen and (max-width: 960px) {
        display: none;
    }
`;
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom' 
import GlobalStyles from './globalStyles'
import { Navbar, Footer } from './components';
import Home from './pages/HomePage/Home';
// import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
      <Router>
          <GlobalStyles />
          {/* <ScrollToTop /> */}
          <Navbar />
          <Home />
          <Footer />
      </Router>
        
    
  );
}

export default App;

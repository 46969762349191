import React, { useState } from 'react'
import { InfoSec, InfoRow, InfoColumn, TextWrapper, Heading, Subtitle, ImgWrapper, Img } from './InfoSection.elements'
import { Container, Button, ButtonGroup } from '../../globalStyles'
import { Link } from 'react-router-dom'
import { MotionConfig } from "framer-motion";
import Typical from 'react-typical'
// import Typist from 'react-typist';

const InfoSection = ({
    primary,
    lightBg,
    headline,
    description,
    buttonLabel,
    img,
    alt,
    imgStart,
    start,
    buttonLabelRight,
}) => {
    const variants = {
        offscreen: {
            y: -50,
            opacity: 0,
        },
        onscreen: (custom) => ({
            y: 0,
            opacity: 1,
            rotate: 0,
            transition: {
                type: "spring",
                bounce: 0,
                duration: 1,
                delay: custom * 0.1
            }
        })
    }
    const [loop, setLoop] = useState(0);
    function onComplete() {
        setLoop(Infinity)
    }
    return (
        <>
            <InfoSec layout lightBg={lightBg}>
                <Container>
                    <InfoRow imgStart={imgStart}>
                        <InfoColumn>
                            <TextWrapper initial="offscreen" whileInView="onscreen" viewport={{ once: true }}>
                                <Heading variants={variants} custom={1} onAnimationComplete={onComplete}>
                                    <Typical
                                        steps={[headline, 1000]}
                                        loop={loop}
                                    />
                                </Heading>
                                <Subtitle variants={variants} custom={2}>{description}</Subtitle>
                                <ButtonGroup variants={variants} custom={3}>
                                    <Link>
                                        <Button big fontBig primary={primary}>
                                            {buttonLabel}
                                        </Button>
                                    </Link>
                                    {
                                        !buttonLabelRight || buttonLabelRight === "" ? <></> : (
                                            <Link>
                                                <Button big fontBig>{buttonLabelRight}</Button>
                                            </Link>
                                        )
                                    }
                                </ButtonGroup>
                            </TextWrapper>
                        </InfoColumn>
                        <InfoColumn>
                            <MotionConfig transition={{ duration: 1 }} viewport={{ once: true }}>
                                <ImgWrapper start={start} whileInView={{ opacity: 1, x: 0 }} initial={{ opacity: 0, x: 80 }}>
                                    <Img id="circle4" src={img} alt={alt} />
                                </ImgWrapper>
                            </MotionConfig>

                        </InfoColumn>
                    </InfoRow>
                </Container>
            </InfoSec>
        </>
    )
}

export default InfoSection;
import React from 'react';
import {
  NewsSection,
  NewsWrapper,
  NewsHeading,
  NewsContainer,
  NewsCard,
  NewsCardInfo,
  NewsCardIcon,
  NewsCardPlan,
  ProofRightIcon,
} from './News.elements';

const News = () => {
  return (
    <NewsSection>
      <NewsWrapper>
        <NewsHeading>Capital & News</NewsHeading>
        <NewsContainer>
          <NewsCard to='/'>
            <NewsCardInfo>
              <NewsCardIcon src={require('../../images/news1.png')}>
              </NewsCardIcon>
              <NewsCardPlan>A new NFT launch strategy: The wave mint</NewsCardPlan>
            </NewsCardInfo>
          </NewsCard>
          <NewsCard to='/'>
            <NewsCardInfo>
              <NewsCardIcon src={require('../../images/news2.png')}>
              </NewsCardIcon>
              <NewsCardPlan>Your First Stop to Web3 — Crypto-Fiat On Ramps and Off Ramps</NewsCardPlan>
            </NewsCardInfo>
          </NewsCard>
          <NewsCard to='/'>
            <NewsCardInfo>
              <NewsCardIcon src={require('../../images/news3.png')}>
              </NewsCardIcon>
              <NewsCardPlan>Crypto Policy & Regulation: What's Going On?</NewsCardPlan>
            </NewsCardInfo>
          </NewsCard>
        </NewsContainer>
      </NewsWrapper>
      <ProofRightIcon />
    </NewsSection>
  );
}

export default News
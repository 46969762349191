import styled, { createGlobalStyle } from 'styled-components';
import { motion } from 'framer-motion';

const GlobalStyles = createGlobalStyle`
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: DXRigraf, sans-serif;
}
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(155, 155, 155, 0.5) transparent;
}
*::-webkit-scrollbar {
  width: 0.5px;
}
*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background: rgba(155, 155, 155, 0.5);
  border-radius: 20px;
  border: transparent;
}
body {
  // background: #BEFFAD;
}
`;


export const Container = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
  @media screen and (max-width: 991px) {
    padding-right: 30px;
    padding-left: 30px;
  }
`;

export const Button = styled.button`
  border-radius: 4px;
  background: ${({ primary }) => (primary ? '#1E3666' : 'transparent')};
  white-space: nowrap;
  padding: ${({ big }) => (big ? '12px 64px' : '10px 20px')};
  color: ${({ primary }) => (primary ? 'var(--color1)' : '#1E3666')};
  font-size: ${({ fontBig }) => (fontBig ? '20px' : '16px')};
  font-weight: 400;
  outline: none;
  border: 2px solid #1E3666;
  cursor: pointer;
  white-space: pre-wrap;
  word-break: break-word;
  word-wrap: break-word;
  // &:hover {
  //   transition: all 0.3s ease-out;
  //   background: #fff;
  //   background-color: ${({ primary }) => (primary ? '#0467FB' : '#4B59F7')};
  // }
  @media screen and (max-width: 960px) {
    margin-bottom: 30px;
  }
  @media screen and (max-width: 480px) {
    width: 80%;
    margin-bottom: 10px;
  }
`;

export const ButtonGroup = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 1200px) {
    width: 100%;
    flex-wrap: wrap;
    flex-direction: column;
    a {
      width: 100%;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      button {
        font-size: 18px;
      }
    }
  }
  @media screen and (max-width: 480px) {
    button {
      width: 80%;
      
    }
  }
  @media screen and (max-width: 320px) {
    button {
      padding-left: 0;
      padding-right: 0;
      font-size: 14px;
    }
  }
`


export default  GlobalStyles;   
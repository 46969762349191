import React, { useEffect, useState } from 'react'
import { IntroductionWrapper, IntroductionP, IntroductionList, IntroductionListItem, IntroductionListItemTitle, IntroductionListItemContent, SliderWrap, Slider, SliderItem } from "./Introduction.element"


const Introduction = () => {
    const variants = {
        offscreen: {
            y: -30,
            opacity: 0,
        },
        onscreen: (custom) => ({
            y: 0,
            opacity: 1,
            rotate: 0,
            transition: {
                type: "spring",
                bounce: 0,
                duration: 3,
                delay: custom * 0.1
            }
        })
    }
    const cardVariants = {
        offscreen: {
            rotateY: -180,
            scale: 0.8,
            opacity: 0,
        },
        onscreen: (custom) => ({
            rotateY: 0,
            scale: 1,
            opacity: 1,
            transition: {
                duration: 2,
                delay: 2 + custom * 0.2
            }
        })
    }
    const [isSmall, setIsSmall] = useState(window.innerWidth <= 1200);
    // function changeSlider() {
    //     if (window.innerWidth <= 1200 && isSmall === false) setIsSmall(true)
    //     else setIsSmall(false)
    // }
    // window.addEventListener('resize', changeSlider);
    useEffect(() => {
        const cardSlider = document.getElementById('card-slider')
        let cards = [];
        if (cardSlider) {
            cards = cardSlider.querySelectorAll(".slider-item")
            if (cards) cards = [...cards]
            if (isSmall) return;
            startAnim(cards);
        }
        function startAnim(array) {
            
            const x = isSmall ? 0 : 79
            if (array.length >= 4) {
                window.TweenMax.fromTo(array[0], 0.5, { x: 0, y: -250, opacity: 1 }, { x: 0, y: -300, opacity: 0, zIndex: 0, delay: 0.03, ease: window.Cubic.easeInOut, onComplete: sortArray(array) });
                window.TweenMax.fromTo(array[1], 0.5, { x, y: 0, opacity: 1, zIndex: 1 }, { x: 0, y: -250, opacity: 1, zIndex: 0, boxShadow: '', ease: window.Cubic.easeInOut });

                window.TweenMax.to(array[2], 0.5, { bezier: [{ x: 0, y: 250 }, { x: isSmall ? 0 : 65, y: 200 }, { x, y: 0 }], boxShadow: '', zIndex: 1, opacity: 1, ease: window.Cubic.easeInOut });

                window.TweenMax.fromTo(array[3], 0.5, { x: 0, y: 300, opacity: 0, zIndex: 0 }, { x: 0, y: 250, opacity: 1, zIndex: 0, ease: window.Cubic.easeInOut },);
            }
        }

        function sortArray(array) {
            clearTimeout(delay);
            var delay = setTimeout(function () {
                var firstElem = array.shift();
                array.push(firstElem);
                return startAnim(array);
            }, 3000)
        }

    })
    return (
        <IntroductionWrapper initial="offscreen" whileInView="onscreen" viewport={{ once: true }}>
            <IntroductionP variants={variants} custom={3}>
                Who we are<br/>
                <span>Easter Capital</span>
            </IntroductionP>

            <IntroductionList variants={variants} custom={3}>
                {
                    isSmall ? (
                        <>
                            <IntroductionListItem variants={cardVariants} custom={1}>
                                <IntroductionListItemTitle>Our strategy</IntroductionListItemTitle>
                                <IntroductionListItemContent>
                                    A project incubation platform focusing on the Web3 field.
                                </IntroductionListItemContent>
                            </IntroductionListItem>
                            <IntroductionListItem variants={cardVariants} custom={2}>
                                <IntroductionListItemTitle>Our vision</IntroductionListItemTitle>
                                <IntroductionListItemContent>
                                    To promote the industrialization of Web3 and realize ecological iteration.
                                </IntroductionListItemContent>
                            </IntroductionListItem> <IntroductionListItem variants={cardVariants} custom={3}>
                                <IntroductionListItemTitle>Our objective</IntroductionListItemTitle>
                                <IntroductionListItemContent>
                                    To invest in incubating 1,000 Web3 projects, serve tens of thousands of global elites to successfully start businesses, and create companies with a market value of tens of billions of dollars.
                                </IntroductionListItemContent>
                            </IntroductionListItem>
                        </>) : (<SliderWrap className="slider-wrap">
                            <Slider id="card-slider">
                                <SliderItem className="slider-item">
                                    <IntroductionListItem variants={cardVariants} custom={2}>
                                        <IntroductionListItemTitle>Our vision</IntroductionListItemTitle>
                                        <IntroductionListItemContent>
                                            To promote the industrialization of Web3 and realize ecological iteration.
                                        </IntroductionListItemContent>
                                    </IntroductionListItem>
                                </SliderItem>
                                <SliderItem className="slider-item">
                                    <IntroductionListItem variants={cardVariants} custom={3}>
                                        <IntroductionListItemTitle>Our objective</IntroductionListItemTitle>
                                        <IntroductionListItemContent>
                                            To invest in incubating 1,000 Web3 projects, serve tens of thousands of global elites to successfully start businesses, and create companies with a market value of tens of billions of dollars.
                                        </IntroductionListItemContent>
                                    </IntroductionListItem>
                                </SliderItem>
                                <SliderItem className="slider-item">
                                    <IntroductionListItem variants={cardVariants} custom={1}>
                                        <IntroductionListItemTitle>Our strategy</IntroductionListItemTitle>
                                        <IntroductionListItemContent>
                                            A project incubation platform focusing on the Web3 field.
                                        </IntroductionListItemContent>
                                    </IntroductionListItem>
                                </SliderItem>
                                <SliderItem className="slider-item">
                                    <IntroductionListItem variants={cardVariants} custom={2}>
                                        <IntroductionListItemTitle>Our vision</IntroductionListItemTitle>
                                        <IntroductionListItemContent>
                                            To promote the industrialization of Web3 and realize ecological iteration.
                                        </IntroductionListItemContent>
                                    </IntroductionListItem>
                                </SliderItem>
                                <SliderItem className="slider-item">
                                    <IntroductionListItem variants={cardVariants} custom={3}>
                                        <IntroductionListItemTitle>Our objective</IntroductionListItemTitle>
                                        <IntroductionListItemContent>
                                            To invest in incubating 1,000 Web3 projects, serve tens of thousands of global elites to successfully start businesses, and create companies with a market value of tens of billions of dollars.
                                        </IntroductionListItemContent>
                                    </IntroductionListItem>
                                </SliderItem>
                                <SliderItem className="slider-item">
                                    <IntroductionListItem variants={cardVariants} custom={1}>
                                        <IntroductionListItemTitle>Our strategy</IntroductionListItemTitle>
                                        <IntroductionListItemContent>
                                            A project incubation platform focusing on the Web3 field.
                                        </IntroductionListItemContent>
                                    </IntroductionListItem>
                                </SliderItem>
                                <SliderItem className="slider-item">
                                    <IntroductionListItem variants={cardVariants} custom={2}>
                                        <IntroductionListItemTitle>Our vision</IntroductionListItemTitle>
                                        <IntroductionListItemContent>
                                            To promote the industrialization of Web3 and realize ecological iteration.
                                        </IntroductionListItemContent>
                                    </IntroductionListItem>
                                </SliderItem>
                                <SliderItem className="slider-item">
                                    <IntroductionListItem variants={cardVariants} custom={3}>
                                        <IntroductionListItemTitle>Our objective</IntroductionListItemTitle>
                                        <IntroductionListItemContent>
                                            To invest in incubating 1,000 Web3 projects, serve tens of thousands of global elites to successfully start businesses, and create companies with a market value of tens of billions of dollars.
                                        </IntroductionListItemContent>
                                    </IntroductionListItem>
                                </SliderItem>
                            </Slider>
                        </SliderWrap>)
                }


            </IntroductionList>
        </IntroductionWrapper>
    )
}

export default Introduction;
import React from 'react';
import { Button, ButtonGroup } from '../../globalStyles';
import {
  FaFacebook,
  FaInstagram,
} from 'react-icons/fa';
import {
  FooterContainer,
  FooterSubscription,
  FooterSubHeading,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
  JoinUs,
  JoinUsButtonGroup,
} from './Footer.elements';
import { Img } from '../InfoSection/InfoSection.elements';

function Footer() {

  const date = new Date();

  return (
    // style={{backgroundImage: "url(" + require("../../images/footer.bg.png") + ")"}}
    <FooterContainer>
      <FooterSubscription>
        <FooterSubHeading>
          It’s a fund ecosystem, ready to experience it?
        </FooterSubHeading>
        <Button fontBig>Contact by Email</Button>
      </FooterSubscription>
      <JoinUs>
        <p>
          Join us as we shape the future of humanity as we move into entirely new territories.
        </p>
        <JoinUsButtonGroup>
          <Button>Pitch us</Button>
          <Button>Invest with us</Button>
        </JoinUsButtonGroup>
      </JoinUs>
      <SocialMedia>
        <SocialMediaWrap>
          <SocialLogo to='/'>
            <Img src={require("../../images/logo.png")} alt='EASTER CAPITAL' />
            <WebsiteRights> © {date.getFullYear()} EASTER CAPITAL</WebsiteRights>
          </SocialLogo>
          <SocialIcons>
            <SocialIconLink href='/' target='_blank' aria-label='Instagram'>
              <FaInstagram />
            </SocialIconLink>
            <SocialIconLink href='/' target='_blank' aria-label='Facebook'>
              <FaFacebook />
            </SocialIconLink>
          </SocialIcons>
        </SocialMediaWrap>
      </SocialMedia>
    </FooterContainer>
  );
}

export default Footer;
export const partnerList = [
    {
        img: require("../../images/partner/4.png"),
        alt: "StanFord University"
    },
    {
        img: require("../../images/partner/5.png"),
        alt: "Harvard University"
    },
    {
        img: require("../../images/partner/2.png"),
        alt: "DeBox"
    },
    {
        img: require("../../images/partner/3.png"),
        alt: "CoinTime"
    },
    {
        img: require("../../images/partner/1.png"),
        alt: "Tencent"
    },
]
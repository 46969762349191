import React from 'react';
import { InfoSection, News, Process, Introduction, Proof, Testimonials } from '../../components';
import { homeObjOne } from './Data';

const Home = () => {
    return (
        <>
            <InfoSection {...homeObjOne} />
            <Introduction />
            <Process />
            <Testimonials />
            <Proof />
            <News />
        </>
    )
}

export default Home;
import styled from 'styled-components';
import { Container } from '../../globalStyles'
import { Link } from 'react-router-dom'


export const Nav = styled.nav`
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    // position: sticky;
    top: 0;
    z-index: 999;
    background-color: var(--bg);
    overflow: visible;
    position: relative;
    z-index: 10;
`

export const NavbarContainer = styled(Container)`
    display: flex;
    justify-content: space-between;
    height: 80px;
    max-width: none;
    ${Container}
`

export const NavLogo = styled(Link)`
    color: #fff;
    justify-self: flex-start;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
`

export const NavIcon = styled.img`
    margin-right: 0.5rem;
    width: 16rem;
    @media screen and (max-width: 480px) {
        width: 10rem;   
    }
`

export const HamburgerIcon = styled.div`
    display: none;

    @media screen and (max-width: 1130px) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;   
    }
`

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    @media screen and (max-width: 1300px) {
        font-size: 16px;
    }
    @media screen and (max-width: 1130px) {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 80px;
        opacity: 1;
        transition: all 0.5s ease;
        background-color: var(--bg);
        left: ${({ click }) => (click ? 0 : '-100%')};
        font-size: 20px;
    }
`
export const NavItem = styled.li`
    height: 80px;
    border-bottom: 2px solid transparent;
    border-radius: 2px;

    @media screen and (max-width: 960px) {
        width: 100%;

        &:hover {
            border-bottom: none;
        }
    }

`

export const NavLinks = styled(Link)`
    color: var(--title-color);
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    

    @media screen and (max-width: 1130px) {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;

        &:hover {
            color: #4b59f7;
            transition: all 0.3s ease;
        }
    }
`

export const NavItemBtn = styled.li`
  @media screen and (max-width: 1130px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
  }
`;

export const NavBtnLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 8px 16px;
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  button {
    padding: 7px 14px;
  }
  @media screen and (max-width: 1300px) {
    button {
        padding: 5px 10px;
        font-size: 18px;
    }
}
`;

export const MenuToggleButton = styled.button`
    outline: none;
    border: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: transparent;
    padding: 10px;
    path {
        fill: #fff;
    }
`
import React, { useRef } from 'react'
import { ProofWrapper, ProofLeftIcon, ProofInfoPart, ProofListWrapper, Img, ImgWrapper, ProofInfoTitle, ProofInfoGroup } from "./Proof.elements"
import { partnerList } from './data'
import { motion, useMotionValue, useScroll, useVelocity, useSpring, useTransform, useAnimationFrame } from 'framer-motion'
import { wrap } from "@motionone/utils";
import { Button, ButtonGroup } from '../../globalStyles';


function ParallaxText({ children, baseVelocity = 100 }) {
    const baseX = useMotionValue(0);
    const { scrollY } = useScroll();
    const scrollVelocity = useVelocity(scrollY);
    const smoothVelocity = useSpring(scrollVelocity, {
        damping: 50,
        stiffness: 400
    });
    const velocityFactor = useTransform(smoothVelocity, [0, 1000], [0, 5], {
        clamp: false
    });

    /**
     * This is a magic wrapping for the length of the text - you
     * have to replace for wrapping that works for you or dynamically
     * calculate
     */
    const y = useTransform(baseX, (v) => `${wrap(-20, -45, v)}%`);

    const directionFactor = useRef(1);
    useAnimationFrame((t, delta) => {
        let moveBy = directionFactor.current * baseVelocity * (delta / 1000);

        /**
         * This is what changes the direction of the scroll once we
         * switch scrolling directions.
         */
        if (velocityFactor.get() < 0) {
            directionFactor.current = -1;
        } else if (velocityFactor.get() > 0) {
            directionFactor.current = 1;
        }

        moveBy += directionFactor.current * moveBy * velocityFactor.get();

        baseX.set(baseX.get() + moveBy);
    });

    /**
     * The number of times to repeat the child text should be dynamically calculated
     * based on the size of the text and viewport. Likewise, the x motion value is
     * currently wrapped between -20 and -45% - this 25% is derived from the fact
     * we have four children (100% / 4). This would also want deriving from the
     * dynamically generated number of children.
     */
    return (
        <div className="parallax">
            <motion.div className="scroller" style={{ y }}>
                {
                    partnerList.map(partner => <ImgWrapper key={"12"} style={{ minHeight: "85px" }}><Img key={partner.alt} src={partner.img} alt={partner.alt}></Img></ImgWrapper>)
                }
                {
                    partnerList.map(partner => <ImgWrapper key={"21"} style={{ minHeight: "85px" }}><Img key={partner.alt} src={partner.img} alt={partner.alt}></Img></ImgWrapper>)
                }
                {
                    partnerList.map(partner => <ImgWrapper key={"23"} style={{ minHeight: "85px" }}><Img key={partner.alt} src={partner.img} alt={partner.alt}></Img></ImgWrapper>)
                }
                {
                    partnerList.map(partner => <ImgWrapper  key={"34"} style={{ minHeight: "85px" }}><Img key={partner.alt} src={partner.img} alt={partner.alt}></Img></ImgWrapper>)
                }
            </motion.div>
        </div>
    );
}

const Introduction = () => {
    const variants = {
        offscreen: {
            y: -20,
            opacity: 0,
        },
        onscreen: (custom) => ({
            y: 0,
            opacity: 1,
            rotate: 0,
            transition: {
                type: "spring",
                bounce: 0,
                duration: 3,
                delay: custom * 0.1
            }
        })
    }
    return (
        <ProofWrapper initial="offscreen" whileInView="onscreen" viewport={{ once: true }}>
            <ProofInfoPart>
                <ProofInfoTitle>
                    We invest in leaders who
                    are reimagining human
                    civilization.
                </ProofInfoTitle>
                <ProofInfoGroup>
                    <Button>
                        Meet Our Team
                    </Button>
                    <Button>Discover Our Portfolio</Button>
                </ProofInfoGroup>
            </ProofInfoPart>
            {/* <ProofTitle variants={variants}>Easter Capital Partner</ProofTitle> */}
            {/* <ProofList variants={variants}>
                {
                    partnerList.map(partner => <ImgWrapper style={{ minHeight: "85px" }}><Img key={partner.alt} src={partner.img} alt={partner.alt}></Img></ImgWrapper>)
                }
            </ProofList> */}
            <ProofListWrapper>
                <ParallaxText baseVelocity={-2}></ParallaxText>
            </ProofListWrapper>

            <ProofLeftIcon></ProofLeftIcon>
        </ProofWrapper>
    )
}

export default Introduction;
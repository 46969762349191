import React, { useEffect, useRef } from 'react';
import { ProcessWrapper, ProcessIconWrapper, ProcessContent, ProcessNode, ArrowImg } from './Process.elements';
import { ReactComponent as ProcessImg } from "../../images/process.svg"
// import { animate } from "framer-motion";

const Process = () => {
    const variants = {
        offscreen: {
            y: -20,
            opacity: 0,
        },
        onscreen: (custom) => ({
            y: 0,
            opacity: 1,
            rotate: 0,
            transition: {
                type: "spring",
                bounce: 0,
                duration: 3,
                delay: custom * 0.1
            }
        })
    }
    const svgRef = useRef()
    useEffect(() => {
        // if (svgRef.current) {
        //     const circle1 = svgRef.current.querySelector("#circle1")
        //     if (circle1) {
        //     }
        // }
    }, [])
    return (
        <ProcessWrapper initial="offscreen" whileInView="onscreen" viewport={{ once: true }}>
            <ProcessContent variants={variants}>Fund Ecosystem Matrix</ProcessContent>
            <ProcessIconWrapper variants={variants}>
                {/* <Img src={require('../../images/process.svg').default} alt='process'></Img> */}
                <ProcessImg ref={svgRef} style={{ marginTop: '112px', overflow: "visible" }} width={"100%"}></ProcessImg>
            </ProcessIconWrapper>
            <ProcessNode variants={variants} {...{ width: "246px", fill: "#fff" }}>Incubator</ProcessNode>
            <ArrowImg variants={variants} src={require('../../images/arrow.svg').default}></ArrowImg>
            <ProcessNode variants={variants} {...{ width: "246px", fill: "#fff" }}>DAO</ProcessNode>
            <ArrowImg variants={variants} src={require('../../images/arrow.svg').default}></ArrowImg>
            <ProcessNode variants={variants} {...{ width: "calc(100% - 30px)", fill: "#9FE4C5" }}>Capital</ProcessNode>
        </ProcessWrapper>
    )
}

export default Process;
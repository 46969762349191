import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ProcessWrapper = styled(motion.section)`
    height: 680px;
    background: #1E3666;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: visible;
    position: relative;
    padding: 0 50px;
    z-index: 2;
    @media screen and (max-width: 1110px) {
        // justify-content: space-around;
        padding: 60px 30px 60px;
        height: min-content;
    }
`

export const  ProcessIconWrapper = styled(motion.div)`
    width: 1338px;
    max-width: 100%;
    height: 100%;
    @media screen and (max-width: 1200px) {
        // justify-content: space-around;
        width: 1088px;
    }
    @media screen and (max-width: 1110px) {
        display: none;
    }
`

export const Img = styled(motion.img)`
  padding-right: 0;
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  margin-top: 112px;
`;

export const ArrowImg = styled(Img)`
    width: 31px;
    height: auto;
    margin: 20px 0;
    display: none;
    @media screen and (max-width: 1110px) {
        display: block;
    }
`

export const ProcessContent = styled.p`
    font-weight: 700;
    font-size: 40px;
    color: var(--bg);
    position: absolute;
    bottom: 100px;
    right: 109px;
    width: 100%;
    text-align: right;
    white-space: pre-wrap;
    word-break: break-word;
    word-wrap: break-word;
    @media screen and (max-width: 1110px) {
        top: 0;
        bottom: 0;
        left: 0;
        text-align: center;
        position: relative;
        margin-bottom: 40px;
    }
`

export const ProcessNode = styled(motion.div)`
    width: ${(props) => props.width};
    height: ${(props) => props.width};
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    background-color: ${(props) => props.fill};
    display: none;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    font-weight: 700;
    color: var(--title-color);
    @media screen and (max-width: 1110px) {
        display: flex;
    }
    @media screen and (max-width: 480px) {
        &:nth-child(3) {
            width: calc(50% - 30px);
            height: calc(50% - 30px);
            font-size: 24px;
        }
        &:nth-child(5) {
            width: calc(50% - 30px);
            height: calc(50% - 30px);
            font-size: 24px;
        }
    }
    
`
export const homeObjOne = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    headline: 'Easter Capital Allin Cryptocurrency',
    description:
      'Incubation platform for projects in the Web3.0 field',
    buttonLabel: 'Learn more',
    buttonLabelRight: 'Contact us',
    imgStart: '',
    img: require('../../images/home.svg').default,
    alt: 'Credit Card',
    start: ''
  };
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const IntroductionWrapper = styled(motion.section)`
    height: 700px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0 30px;
    z-index: 3;
    overflow: visible;
    @media screen and (max-width: 1200px) {
        flex-direction: column;
        height: min-content;
        // min-height: 100%;
        padding: 30px;
    }
`

export const IntroductionP = styled(motion.p)`
    width: 550px;
    font-weight: 700;
    color: var(--title-color);
    font-size: 40px;
    margin-right: 20px;
    span {
        font-size: 30px;
        font-weight: 500;
    }
    @media screen and (max-width: 1200px) {
        width: 100%;
        text-align: center;
        margin: 30px 0 50px;
    }
`

export const IntroductionList = styled(motion.div)`
    width: 590px;
    position: relative;
    z-index: 2;
    padding: 220px 0 60px;
    @media screen and (max-width: 1200px) {
        width: 100%;
        padding: 0;
        text-align: center;
    }
`

export const IntroductionListWrapper = styled.div`
    position:absolute;
    width: 100%;
    top: 50%;
    z-index: 2;
    transform: translateY(-50%);
    padding: 200px 0 60px;
`

export const IntroductionListItem = styled(motion.div)`
    width: 100%;
    padding: 20px;
    box-shadow: 0 2px 15px #0000001a;
    min-height: 230px;
    height: min-content;
    width: 100%;
    margin-bottom: 30px;
    background-color: #fff;
    &:last-child {
        margin-bottom: 0;
    }
`


export const IntroductionListItemTitle = styled.h2`
    white-space: pre-wrap;
    width: 348px;
    word-break: break-word;
    word-wrap: break-word;
    font-weight: 700;
    color: var(--title-color);
    font-size: 30px;
    margin-bottom: 10px;
    @media screen and (max-width: 1200px) {
        width: 100%;
        text-align: center;
    }
`

export const IntroductionListItemContent = styled.p`
    white-space: pre-wrap;
    word-break: break-word;
    word-wrap: break-word;
    font-weight: 400;
    color: var(--content-color);
    font-size: 20px;
    line-height: 1.8;
`

export const SliderWrap = styled.div`
height: 100%;
width: 100%;
position: relative;
height: 700px;
`

export const Slider = styled.div`
        position: absolute;
        width: 100%;
        height: 100%;
        top: 30%;
        left: 0;
`

export const SliderItem = styled.div`
width: 100%;
        display: flex;
        justify-content: flex-start;
        opacity: 0;
        z-index: 0;
        position: absolute;
        left: 0;
        top: 0;
`
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Container } from '../../globalStyles';


export const InfoSec = styled(motion.div)`
    color: #fff;
    padding: 30px 0;
    background-color: var(--bg);
    position: relative;
    z-index: 4;
    overflow: hidden;
    @media screen and (max-width: 1200px) {
      padding: 30px 0;
    }
`

export const InfoRow = styled.div`
  display: flex;
  margin: 0 -15px -30px -15px;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: ${({ imgStart }) => (imgStart ? 'row-reverse' : 'row')};
  @media screen and (max-width: 1200px) {
    margin: 0;
  }
`;

export const InfoColumn = styled.div`
  padding: 20px 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;

  @media (min-width: 480px) and (max-width: 1200px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    p {
      max-width: unset;
    }
  }

  @media screen and (max-width: 480px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
`;

export const TextWrapper = styled(motion.div)`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;

  @media screen and (max-width: 1200px) {
    padding-bottom: 65px;
  }
`;

export const ImgWrapper = styled(motion.div)`
  width: 580px;
  height: 547px;
  display: flex;
  justify-content: ${({ start }) => (start ? 'flex-start' : 'flex-end')};
  @media screen and (max-width: 1200px) {
    width: unset;
    max-width: 100%;
    height: auto;
  }
`;

export const TopLine = styled.div`
  color: ${({ lightTopLine }) => (lightTopLine ? '#a9b3c1' : '#1E3666')};
  font-size: 18px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  margin-bottom: 16px;
`;

export const Img = styled.img`
  padding-right: 0;
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  position: relative;
  // top: 30px;
  @media screen and (max-width: 1200px) {
    top: 0;
  }
`;

export const Heading = styled(motion.h1)`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.2em;
  font-weight: 900;
  letter-spacing: 0;
  white-space: pre-wrap;
  word-break: break-word;
  word-wrap: break-word;
  color: #1E3666;
  @media screen and (max-width: 960px) {
    font-size: 38px;
  }
`;

export const Subtitle = styled(motion.p)`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 28px;
  line-height: 1.2;
  color: #25313C;
  @media screen and (max-width: 960px) {
    max-width: unset;
    text-align: center;
  }
`;

export const InfoSectionContainer = styled(Container)`
  padding: 30px 50px;
@media screen and (max-width: 991px) {
  padding-right: 30px;
  padding-left: 30px;
}
`
import React, { useRef } from 'react'
import { TestimonialsWrapper, Wrapper, Avatar, Name, Title, Info, Top, Column, ScrollWrap } from "./Testimonials.elements"
import { AnimatePresence, motion, useMotionValue, useScroll, useVelocity, useSpring, useTransform, useAnimationFrame } from 'framer-motion'
import { useInterval } from 'react-use'
import { teamList } from './data'
import { wrap } from "@motionone/utils";

function ParallaxText({ children, baseVelocity = 100 }) {
    const baseX = useMotionValue(0);
    const { scrollY } = useScroll();
    const scrollVelocity = useVelocity(scrollY);
    const smoothVelocity = useSpring(scrollVelocity, {
        damping: 50,
        stiffness: 400
    });
    const velocityFactor = useTransform(smoothVelocity, [0, 1000], [0, 5], {
        clamp: false
    });

    /**
     * This is a magic wrapping for the length of the text - you
     * have to replace for wrapping that works for you or dynamically
     * calculate
     */
    const x = useTransform(baseX, (v) => `${wrap(-20, -45, v)}%`);

    const directionFactor = useRef(1);
    useAnimationFrame((t, delta) => {
        let moveBy = directionFactor.current * baseVelocity * (delta / 1000);

        /**
         * This is what changes the direction of the scroll once we
         * switch scrolling directions.
         */
        if (velocityFactor.get() < 0) {
            directionFactor.current = -1;
        } else if (velocityFactor.get() > 0) {
            directionFactor.current = 1;
        }

        moveBy += directionFactor.current * moveBy * velocityFactor.get();

        baseX.set(baseX.get() + moveBy);
    });

    /**
     * The number of times to repeat the child text should be dynamically calculated
     * based on the size of the text and viewport. Likewise, the x motion value is
     * currently wrapped between -20 and -45% - this 25% is derived from the fact
     * we have four children (100% / 4). This would also want deriving from the
     * dynamically generated number of children.
     */
    return (
        <div className="parallax">
            <motion.div className="scroller" style={{ x, }}>
                <motion.span key="1">{children} </motion.span>
                <motion.span key="2">{children} </motion.span>
                <motion.span key="3">{children} </motion.span>
                <motion.span key="4">{children} </motion.span>
            </motion.div>
        </div>
    );
}

const Testimonials = () => {
    const [count, setCount] = React.useState(0);
    useInterval(
        () => {
            if (count >= teamList.length - 1) {
                return setCount(0)
            }
            setCount(count + 1);
        },
        2000
    );
    const getCurrTeamMember = () => {
        return teamList[count]
    }
    const variants = {
        active: {
            opacity: 0,
        },
        inactive: {
            opacity: 1,
            transition: { duration: 4 }
        }
    }
    return (
        <TestimonialsWrapper initial="active" animate="inactive">
            <AnimatePresence>
                <Wrapper>
                    <Top>
                        <Avatar variants={variants} src={getCurrTeamMember().avatar}></Avatar>
                        <Column>
                            <Name variants={variants}>{getCurrTeamMember().name}</Name>
                            <Title variants={variants}>{getCurrTeamMember().title}</Title>
                        </Column>
                    </Top>
                    <Info variants={variants}>{getCurrTeamMember().info}</Info>
                </Wrapper>
            </AnimatePresence>
            <ScrollWrap>
                <ParallaxText baseVelocity={-2}>Easter Capital Allin Cryptocurrency Easter Capital</ParallaxText>
            </ScrollWrap>
        </TestimonialsWrapper>
    )
}

export default Testimonials;
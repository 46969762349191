import React, { useState, useEffect } from 'react'
import {
    Nav,
    NavbarContainer,
    NavLogo,
    NavIcon,
    // HamburgerIcon,
    NavMenu,
    NavItem,
    NavLinks,
    NavItemBtn,
    NavBtnLink,
} from './Navbar.elements'
// import { FaTimes, FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib'
import { Button } from '../../globalStyles';
import { MenuToggle } from "./MenuToggle";
import { useAnimate, stagger } from "framer-motion"

function useMenuAnimation(isOpen) {
    const [scope, animate] = useAnimate();

    useEffect(() => {
        const menuAnimations = isOpen
            ? [
                [
                    "nav",
                    { transform: "translateX(0%)" },
                    { ease: [0.08, 0.65, 0.53, 0.96], duration: 0.6 }
                ],
                [
                    "li",
                    { transform: "scale(1)", opacity: 1, filter: "blur(0px)" },
                    { delay: stagger(0.05), at: "-0.1" }
                ]
            ]
            : [
                [
                    "li",
                    { transform: "scale(0.5)", opacity: 0, filter: "blur(10px)" },
                    { delay: stagger(0.05, { from: "last" }), at: "<" }
                ],
                ["nav", { transform: "translateX(-100%)" }, { at: "-0.1" }]
            ];

        animate([
            [
                "path.top",
                { d: isOpen ? "M 3 16.5 L 17 2.5" : "M 2 2.5 L 20 2.5" },
                { at: "<" }
            ],
            ["path.middle", { opacity: isOpen ? 0 : 1 }, { at: "<" }],
            [
                "path.bottom",
                { d: isOpen ? "M 3 2.5 L 17 16.346" : "M 2 16.346 L 20 16.346" },
                { at: "<" }
            ],
            ...menuAnimations
        ]);
    }, [animate, isOpen]);

    return scope;
}

function Navbar() {


    const handleClick = () => {
        if (!isOpen) document.body.style = "position:fixed";
        else document.body.style = "";
        setIsOpen(!isOpen);
    }

    const [button, setButton] = useState(true);

    const [homeClick, setHomeClick] = useState(false);
    const [servicesClick, setServicesClick] = useState(false);
    const [productsClick, setProductsClick] = useState(false);

    const handleHomeClick = () => {
        setHomeClick(true);
        setProductsClick(false);
        setServicesClick(false);
    }
    const handleServicesClick = () => {
        setHomeClick(false);
        setProductsClick(false);
        setServicesClick(true);
    }
    const handleProductsClick = () => {
        setHomeClick(false);
        setProductsClick(true);
        setServicesClick(false);
    }

    const closeMobileMenu = () => setIsOpen(false);

    const showButton = () => {
        // so if the screenSize is <= 960px then set button state to false
        if (window.innerWidth <= 1130) {
            setButton(true)
        } else {
            setIsOpen(true)
            setButton(false)
        }
    }

    useEffect(() => {
        showButton();
    }, [])

    window.addEventListener('resize', showButton);
    const [isOpen, setIsOpen] = useState(false);
    const scope = useMenuAnimation(isOpen);

    const toggle = () => {
        if (!isOpen) document.body.style = "position:fixed";
        else document.body.style = "";
        setIsOpen(!isOpen)
    }

    // const scope = useMenuAnimation(isOpen);
    return (
        <>
            <IconContext.Provider value={{ color: '#fff' }}>
                <Nav ref={scope}>
                    <NavbarContainer>
                        <NavLogo to='/'>
                            <NavIcon src={require("../../images/logo.png")} alt='EASTER CAPITAL' />
                        </NavLogo>
                        {
                            button ? <MenuToggle
                                toggle={toggle}
                            >
                            </MenuToggle> : <></>
                        }

                        <NavMenu onClick={handleClick} click={isOpen} >
                            <NavItem onClick={handleHomeClick} homeClick={homeClick}>
                                <NavLinks to='/' onClick={closeMobileMenu}>
                                    PORTFOLIO
                                </NavLinks>
                            </NavItem>


                            <NavItem onClick={handleServicesClick} servicesClick={servicesClick}>
                                <NavLinks to='/' onClick={closeMobileMenu}>
                                    RESEARCH
                                </NavLinks>
                            </NavItem>


                            <NavItem onClick={handleProductsClick} productsClick={productsClick}>
                                <NavLinks to='/' onClick={closeMobileMenu}>
                                    News & Events
                                </NavLinks>
                            </NavItem>
                            <NavItem onClick={handleProductsClick} productsClick={productsClick}>
                                <NavLinks to='/' onClick={closeMobileMenu}>
                                    MEDIA
                                </NavLinks>
                            </NavItem>
                            <NavItem onClick={handleProductsClick} productsClick={productsClick}>
                                <NavLinks to='/' onClick={closeMobileMenu}>
                                    HACKATHON
                                </NavLinks>
                            </NavItem>
                            <NavItem onClick={handleProductsClick} productsClick={productsClick}>
                                <NavLinks to='/' onClick={closeMobileMenu}>
                                    ABOUT
                                </NavLinks>
                            </NavItem>

                            <NavItemBtn >
                                {button ? (
                                    <NavBtnLink to='/'>
                                        <Button primary>Follow us</Button>
                                    </NavBtnLink>
                                ) : (
                                    <NavBtnLink to='/'>
                                        <Button onClick={closeMobileMenu} fontBig primary>Follow us</Button>
                                    </NavBtnLink>
                                )}

                            </NavItemBtn>
                        </NavMenu>
                    </NavbarContainer>
                </Nav>
            </IconContext.Provider>
        </>
    )
}

export default Navbar

import styled from 'styled-components';
import { ReactComponent as proofLeft } from "../../images/proof.left.svg"
import { motion } from 'framer-motion';
import { ButtonGroup } from '../../globalStyles';


export const ProofWrapper = styled(motion.section)`
    min-height: 439px;
    height: min-content;
    background: var(--title-color);
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    
    @media screen and (max-width: 1200px) {
        padding: 0;
        justify-content: space-around;
        flex-direction: column;
    }
    .parallax {
  overflow: hidden;
  letter-spacing: -2px;
  line-height: 1;
  margin: 0;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.parallax .scroller {
  font-weight: 600;
  // text-transform: uppercase;
  font-size: 64px;
  display: flex;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.parallax span {
  display: block;
  margin-right: 30px;
}
`

export const ProofTitle = styled(motion.h2)`
    font-size: 40px;
    color: #bdffad;
    text-align: center;
    height: 48px;
    @media screen and (max-width: 480px) {
        font-size: 36px;
    }
`

export const ProofList = styled(motion.div)`
    display: flex;
    align-items: center;
    min-height: 80px;
    z-index: 1;
    justify-content: center;
    width: 100%;
    margin-top: 40px;
    flex-wrap: wrap;
    @media screen and (max-width: 1200px) {
        height: min-content;
        flex-direction: column;
    }
`

export const ProofListWrapper = styled(motion.div)`
height: 439px;
overflow: hidden;
width: 50%;
background-color: var(--title-color);
@media screen and (max-width: 1110px) {
    width: 100%;
}
`

export const ImgWrapper = styled(motion.div)`
    min-height: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
`

export const Img = styled(motion.img)`
  padding-right: 0;
  border: 0;
  max-width: 100%;
  width: 150px;
  vertical-align: middle;
  display: inline-block;
  margin: 0 30px;
  height: min-content;

  @media screen and (max-width: 1200px) {
    // margin: 0 0 36px 0;
}
`;

export const ProofLeftIcon = styled(proofLeft)`
    width: 364px;
    bottom: -208px;
    height: 417px;
    left: -60px;
    position: absolute;
    @media screen and (max-width: 1200px) {
        width: 312px;
    }
    @media screen and (max-width: 480px) {
        width: 200px;
    }
`;


export const ProofInfoPart = styled(motion.div)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
    min-height: 439px;
    padding: 50px;
    background-color: var(--bg);
    @media screen and (max-width: 1200px) {
        width: 100%;
        text-align: center;
    }
`

export const ProofInfoGroup = styled(ButtonGroup)`
  justify-content: flex-end;
  button {
    border-radius: 30px;
    border-width: 1px;
    margin-right: 40px;
    max-width: 240px;
}
@media screen and (max-width: 1200px) {
    align-items: center;
    margin-top: 40px;
    button {
        width: 80%;
        margin-right: 0;
        margin-bottom: 20px;
    }
  }
`

export const ProofInfoTitle = styled(motion.h2)`
    color: var(--title-color);
    font-size: 50px;
    @media screen and (max-width: 1200px) {
        text-align: center;
        font-size: 38px;
      }
      @media screen and (max-width: 480px) {
        p {
          font-size: 26px;
        }
      }
`
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ButtonGroup } from '../../globalStyles';

export const FooterContainer = styled.div`
  background-color: var(--title-color);
  background-size: 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const FooterSubscription = styled.section`
  width: 100%;
  max-width: 1300px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 24px 60px;
  height: min-content;
  color: #fff;
  button {
    background-color: var(--bg);
    height: 77px;
    font-size: 30px;
    font-weight: 600;
    color: var(--title-color);
  }
  @media screen and (max-width: 960px) {
    flex-wrap: wrap;
    justify-content: center;
    padding: 24px 30px;
    button {
      width: 300px;
    }
  }
  @media screen and (max-width: 480px) {
    button {
      width: 80%;
      font-size: 22px;
    }
  }
  @media screen and (max-width: 320px) {
    button {
      width: 80%;
      padding-left: 0;
      padding-right: 0;
      font-size: 14px;
    }
  }
`;

export const JoinUs = styled.div`
  background: var(--bg);
  width: 100%;
  padding: 0 60px 60px;
  p {
    font-size: 60px;
    width: 70%;
    padding: 60px 0;
    color: var(--title-color);
  }
  button {
    border-radius: 30px;
    border-width: 1px;
    margin-right: 40px;
  }
  @media screen and (max-width: 1200px) {
    button {
      margin-bottom: 20px;
    }
  }
  @media screen and (max-width: 960px) {
    padding: 0 30px 30px;
    text-align: center;
    p {
      width: 100%;
      font-size: 30px;
    }
  }
  @media screen and (max-width: 480px) {
    p {
      font-size: 26px;
    }
  }
`

export const JoinUsButtonGroup = styled(ButtonGroup)`
  justify-content: flex-start;
  button {
    max-width: 240px;
  }
  @media screen and (max-width: 960px) {
    align-items: center;
    justify-content: center;
    button {
      margin-right: 0;
      width: 80%;
    }
  }
` 

export const FooterSubHeading = styled.p`
  width: 446px;
  font-size: 30px;
  height: 362px;
  font-weight: 700;
  color: var(--bg);
  display: flex;
  align-items: center;
  text-align: left;
  @media screen and (max-width: 960px) {
    width: 100%;
    text-align: center;
    height: 180px;
    justify-content: center;
  }
`;

export const FooterSubText = styled.p`
  margin-bottom: 24px;
  font-size: 20px;
`;

export const Form = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 820px) {
    flex-direction: column;
    width: 80%;
  }
`;

export const FormInput = styled.input`
  padding: 10px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 16px;
  border: 1px solid #fff;

  &::placeholder {
    color: #242424;
  }

  @media screen and (max-width: 820px) {
    width: 100%;
    margin: 0 0 16px 0;
  }
`;

export const FooterLinksContainer = styled.div`
  width: 100%;
  // max-width: 1000px;
  display: flex;
  justify-content: center;
  padding: 0 60px;

  @media screen and (max-width: 820px) {
    padding-top: 32px;
  }
`;

export const FooterLinksWrapper = styled.div`
  display: flex;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const FooterLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
  color: #fff;

  @media screen and (max-width: 420px) {
    margin: 0;
    padding: 10px;
    width: 100%;
  }
`;

export const FooterLinkTitle = styled.h2`
  margin-bottom: 16px;
`;

export const FooterLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;

  &:hover {
    color: #0467fb;
    transition: 0.3s ease-out;
  }
`;

export const SocialMedia = styled.section`
  // max-width: 1000px;
  width: 100%;
  padding: 1rem 60px;
  background-color: #fff;
  @media screen and (max-width: 960px) {
    padding: 1rem 30px;
  }
`;

export const SocialMediaWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  // max-width: 1000px;
  margin: 0 auto;

  @media screen and (max-width: 820px) {
    // flex-direction: column;
  }
`;

export const SocialLogo = styled(Link)`
  color: #1E3666;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  flex-direction: column;
  img {
    height: auto;
    width: 120px;
  }
  &:hover {
    color: #0467fb;
    transition: 0.3s ease-out;
  }
  @media screen and (max-width: 960px) {
    font-size: 1.5rem;
  }
  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
  @media screen and (max-width: 320px) {
    font-size: 14px;
  }
`;

export const WebsiteRights = styled.small`
  color: #25313C;
  font-size: 14px;
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 240px;
  @media screen and (max-width: 960px) {
    width: 160px;
  }
`;

export const SocialIconLink = styled.a`
  color: #1E3666;
  font-size: 24px;
  margin-left: 20px;
  height: 24px;
  &:hover {
    color: #0467fb;
    transition: 0.3s ease-out;
  }
`;